import React from "react";
import {
    heading2,
    heading3,
    getContainerStyle,
    mobileHeading3,
    mobileHeading4,
} from "../components/styles";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {wrapContent} from "../components/content";
import {ResearchItem} from "../components/research";
import {workingPapers, publications, otherPublications} from "../data/projects_list";

const getMainContainerStyle = (breakpoints) => {
    const baseStyle = getContainerStyle(breakpoints)
    return {
        ...baseStyle,
        height:"auto",
        width:"auto",
        maxHeight:"none",
        overflowY:"scroll"
    }
}


const getHeading2Style = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileHeading3 : heading2
    return {
        ...baseStyle,
        fontWeight: "900",
        marginBottom: breakpoints.sm ? "1rem" : "2rem"
    }
}

const getHeading3Style = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileHeading4 : heading3
    return {
        ...baseStyle,
        fontWeight: "bold",
        marginBottom: breakpoints.sm ? "0.7rem" : "0.5rem"
    }
}



const ResearchPage = () => {
    const breakpoints = useBreakpoint()
    return (
        <div style={getMainContainerStyle(breakpoints)}>
            <h2 style={getHeading2Style(breakpoints)}>research </h2>
            <h3 style={getHeading3Style(breakpoints)}>published papers (economics)</h3>
            {
                publications.map(p => (
                    <ResearchItem breakpoints={breakpoints} id={p.id} title={p.title} comment={p.comment}
                                  linkURL={p.linkURL} linkType={p.linkType}
                                  coauthors={p.coauthors} abstract={p.abstract}/>
                ))
            }
            <h3 style={{ ...getHeading3Style(breakpoints), marginTop:"1rem" }}>working papers</h3>
            {
                workingPapers.map(p => (
                    <ResearchItem breakpoints={breakpoints} id={p.id} title={p.title} comment={p.comment}
                                  linkURL={p.linkURL} linkType={p.linkType}
                                  coauthors={p.coauthors} abstract={p.abstract}/>
                ))
            }
            <h3 style={{ ...getHeading3Style(breakpoints), marginTop:"1rem" }}>other publications</h3>
            {
                otherPublications.map(p => (
                    <ResearchItem breakpoints={breakpoints} title={p.title} comment={p.comment}
                                  linkURL={p.linkURL} linkType={p.linkType}
                                  coauthors={p.coauthors} abstract={p.abstract}/>
                ))
            }

        </div>
    )
}

export default wrapContent(ResearchPage)