import * as React from "react";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import {
    bodyText,
    color1, color2,
    color3, color4,
    heading4,
    mobileBodyText,
    mobileHeading4,
    mobileURLColor,
    urlColor
} from "./styles";
import {useSpring, animated} from "react-spring";


const getHeading4Style = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileHeading4 : heading4;
    const urlStyle = breakpoints.sm ? mobileURLColor : urlColor;
    return {
        ...baseStyle,
        ...urlStyle,
        fontWeight: "bold",
        textDecoration: "none",
        marginBottom: "0.5rem",
        cursor: "default",

    }
}

const getCaptionStyle = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileBodyText : bodyText;
    return {
        ...baseStyle,
        fontStyle: "oblique",
        fontSize: "0.85em",
        paddingTop: "0.2rem"
    }
}

const getAbstractHeadingStyle = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileBodyText : bodyText;
    return {
        ...baseStyle,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",

    }
}

const getAbstractBodyStyle = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileBodyText : bodyText;
    return {
        ...baseStyle,
        overflowY: "scroll",
        fontSize: "0.875em"
    }
}

const abstractArrowStyle = {
    fontFamily: "Material Icons"
}

const AbstractContents = ({ abstract, breakpoints, expanded  }) => {

    const expandBodyStyle = useSpring({
        height: expanded ? "200px" : "0",
    })

    const expandHeadingStyle = useSpring({
        marginBottom: expanded ? "10px" : "0px"
    })

    const bodyStyle = {
        ...getAbstractBodyStyle(breakpoints),
        ...expandBodyStyle
    }

    const headingStyle = {
        ...getAbstractHeadingStyle(breakpoints),
        ...expandHeadingStyle,
    }

    const expandArrowStyle = useSpring({
        transform: expanded ? "rotateZ(540deg)" : "rotateZ(0deg)"
    })

    const arrowStyle = {
        ...abstractArrowStyle,
    }


    return (
       <div>
           <animated.div style={headingStyle}>
               <h4>abstract </h4>
               <animated.div style={expandArrowStyle}>
                   <span style={arrowStyle}>arrow_forward</span>
               </animated.div>
           </animated.div>
           <animated.div style={bodyStyle}>
               {abstract}
           </animated.div>
       </div>
    )
}


const getPaperContainerStyle = (breakpoints) => {
    return {
        margin: "0.5rem 0rem 0.5rem 0rem",
        padding: "0.7rem",
        border: "1px solid ".concat(color3),
        overflowX: "hidden",
        overflowY: "hidden",
        boxSizing: "border-box",
        borderRadius: "10px",
    }
}

const getAbstractContainerStyle = (breakpoints) => {
    return {
        marginTop: "0.7rem",
        padding: "0.3rem 0.5rem 0.3rem 0.5rem",
        border: "1px solid ".concat(breakpoints.sm ? color3 : color4),
        overflowX: "hidden",
        overflowY: "hidden",
        cursor: "pointer",
        boxSizing: "border-box",
        borderRadius: "10px",
    }
}

const AnimatedAbstractContents = animated(AbstractContents)

const Abstract = ({ id, abstract, breakpoints }) => {
    const [expanded, setExpand] = React.useState(false)
    const [hovered, setHover] = React.useState(false)

    const expandContainerStyle = useSpring({
        width: expanded ? "100%" : "30%"
    });

    const shouldReactToHover = hovered && !expanded;

    const hoverStyle = useSpring({
        backgroundColor: breakpoints.sm ? (shouldReactToHover ? color3 + "FF" : color3 + "00") : shouldReactToHover ? color3 + "60" : color3 + "00",
        color: breakpoints.sm ? (shouldReactToHover ? color1 : color2) : shouldReactToHover ?  color1 : color1
    })

    const containerStyle = {
        ...getAbstractContainerStyle(breakpoints),
        ...expandContainerStyle,
        ...hoverStyle
    }

    const handleClick = () => {
        setExpand(!expanded)
        typeof window !== "undefined" && expanded && window.gtag("abstract_view", {
            paper_id: id
        })
    }

    return (
        <animated.div style={containerStyle}
                      onClick={handleClick}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}>
            <AnimatedAbstractContents abstract={abstract} breakpoints={breakpoints} expanded={expanded} />
        </animated.div>
    )
}

const PaperLink = ({ breakpoints, linkType, linkURL, id}) => {

    const baseStyle = breakpoints.sm ? mobileBodyText : bodyText;
    const paperLinkStyle = {
        ...baseStyle,
        fontSize:"0.9em"
    }

    return <OutboundLink href={linkURL} style={paperLinkStyle} onClick={()=>{typeof window !== "undefined" && window.gtag("paper_view", {paper_id: id})}}>{linkType}</OutboundLink>
}


const ResearchItem = ({ id, breakpoints, linkType, linkURL, title, abstract, coauthors, comment}) => {
    const [expanded, setExpand] = React.useState(false)
    const [hovered, setHover] = React.useState(false)

    const expandContainerStyle = useSpring({
        width: "100%"
    });

    const shouldReactToHover = hovered && !expanded;

    const hoverStyle = useSpring({
        backgroundColor: breakpoints.sm ? (shouldReactToHover ? color3 + "FF" : color3 + "00") : shouldReactToHover ? color3 + "60" : color3 + "00",
        color: breakpoints.sm ? (shouldReactToHover ? color1 : color2) : shouldReactToHover ?  color1 : color1
    })

    const containerStyle = {
        ...getPaperContainerStyle(breakpoints),
        ...expandContainerStyle,
        ...hoverStyle
    }

    return (
        <animated.div style={containerStyle} onClick={() => setExpand(!expanded)}>
            <div style={getHeading4Style(breakpoints)}>
                { title }
            </div>
            { coauthors ? <p style={getCaptionStyle(breakpoints)}>{coauthors}</p> : null }
            { comment ? <p style={getCaptionStyle(breakpoints)}>{comment}</p>: null}
            { linkType ? <PaperLink id={id} linkType={linkType} linkURL={linkURL} breakpoints={breakpoints}/>: <p style={getCaptionStyle(breakpoints)}>draft coming soon.</p>}
            { abstract ? <Abstract id={id} abstract={abstract} breakpoints={breakpoints}/> : null }
        </animated.div>
    )
}

export {ResearchItem}
